import React, { useState } from 'react';
import { FaMapMarkerAlt, FaTimes, FaWhatsapp, FaPhoneAlt, FaEnvelope, FaInstagram, FaUser, FaBars } from 'react-icons/fa';
import { Link ,  useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../Assets/logo.jpg';
import Footer from './Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for toast notifications

const Contact = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenUserMenu, setIsOpenUserMenu] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem('token');
      await axios.post('https://ituzebe.onrender.com/api/messages', formData, {
        headers: { Authorization: `Bearer ${token}` }
      });

      // Display success toast notification
      toast.success('Message sent successfully!');

      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        message: ''
      });
    
    } catch (error) {
      console.error('Error submitting form:', error);
      // Display error toast notification
      toast.error('Failed to send message. Please try again.');
    }
  };

  const handleNavbarToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/signin');
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-yellow-100 text-white flex flex-col" style={{ fontFamily: '"Nunito", sans-serif' }}>
      <header className="sticky top-0 z-20 shadow-lg flex justify-between items-center p-4 bg-gradient-to-r from-orange-900 to-green-900 mx-5 my-2 rounded-full backdrop-blur-lg bg-opacity-80 transition-all duration-300">
        <div className="flex items-center space-x-4 mx-5">
          <img src={logo} alt="Ituze Garden Palace Logo" className="h-12 w-12 rounded-full object-cover border-2 border-white shadow-md hover:scale-105 transition-transform duration-300" />
        </div>
        <div className="flex items-center space-x-6">
          <button onClick={handleNavbarToggle} className="text-2xl md:hidden text-white hover:text-red-500 transition-colors duration-300">
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
          <nav className={`flex-col md:flex-row ${isOpen ? 'flex' : 'hidden'} md:flex`}>
            <ul className="block md:flex md:space-x-8 md:items-center text-white font-semibold md:ml-auto">
              <li className="hover:underline hover:text-red-500 transition duration-300"><Link to="/">Home</Link></li>
              <li className="hover:underline hover:text-red-500 transition duration-300"><Link to="/gallery">Gallery</Link></li>
              <li className="hover:underline hover:text-red-500 transition duration-300"><Link to="/reservation">Reservation</Link></li>
              <li className="hover:underline hover:text-red-500 transition duration-300"><Link to="/menu">Menu</Link></li>
              <li className="hover:underline hover:text-red-500 transition duration-300"><Link to="/contact">Contacts</Link></li>
            </ul>
          </nav>
          <div className="relative">
            <FaUser className="text-2xl text-white cursor-pointer hover:text-red-500 transition duration-300" onClick={() => setIsOpenUserMenu(!isOpenUserMenu)} />
            {isOpenUserMenu && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 text-black z-30">
                <Link to="/profile" className="block px-4 py-2 hover:bg-gray-100 transition duration-200">Profile</Link>
                <button onClick={handleLogout} className="block w-full text-left px-4 py-2 hover:bg-gray-100 transition duration-200">Log Out</button>
              </div>
            )}
          </div>
        </div>
      </header>

      <div className="flex justify-center items-center flex-col">
        <div className="container mx-auto px-6 py-10">
          <h1 className="text-3xl md:text-4xl font-bold mb-10 text-center text-yellow-700">Contact</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Contact Information */}
            <div className="space-y-6">
              <div className="flex items-center">
                <FaMapMarkerAlt className="text-2xl text-yellow-700 mr-4" />
                <div>
                  <p className="font-medium text-gray-800">KN 14 AVE, KG 623 St, Kigali - Masaka</p>
                </div>
              </div>
              <div className="flex items-center">
                <FaPhoneAlt className="text-2xl text-yellow-700 mr-4" />
                <div>
                  <p className="font-medium text-gray-800">+250 784 503 706</p>
                </div>
              </div>
              <div className="flex items-center">
                <FaEnvelope className="text-2xl text-yellow-700 mr-4" />
                <div>
                  <p className="font-medium text-gray-800">ituzegardenpalace@gmail.com</p>
                </div>
              </div>
              <div className="flex space-x-4 mt-4 text-gray-700">
                <ul className="flex justify-center space-x-6">
                  <li>
                    <a href="https://wa.me/250784503706" aria-label="WhatsApp" className="hover:text-orange-400 transition-colors duration-300">
                      <FaWhatsapp className="text-xl" />
                    </a>
                  </li>
                  <li>
                    <a href="mailto:ituzegardenpalace@gmail.com" aria-label="Email" className="hover:text-orange-400 transition-colors duration-300">
                      <FaEnvelope className="text-xl" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/ituzegardenpalace" aria-label="Instagram" className="hover:text-orange-400 transition-colors duration-300">
                      <FaInstagram className="text-xl" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            
            <form onSubmit={handleSubmit} className="text-black flex flex-col space-y-4 p-6 border border-gray-300 rounded-lg shadow-lg">    <ToastContainer />
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <input
                type="email"
                name="email"
                placeholder="Email *"
                value={formData.email}
                onChange={handleChange}
                className="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
              <textarea
                name="message"
                placeholder="Message *"
                value={formData.message}
                onChange={handleChange}
                className="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 h-32 resize-none"
                required
              ></textarea>
              <button type="submit" className="bg-yellow-700 text-white py-2 rounded-md hover:bg-yellow-900 transition duration-300">Send</button>
            </form>
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
};

export default Contact;
