import React, { useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import backgroundImage from '../Assets/vector.jpg';
import logo from '../Assets/logo.jpg'; // Import the logo

const SignUp = () => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    username: '',
    phonenumber: '',
    email: '',
    password: '',
    confirmPassword: '',
    agree: false,
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === 'checkbox' ? checked : value;
    setFormData((prevState) => ({ ...prevState, [name]: inputValue }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.agree) {
      newErrors.agree = 'Please agree to the terms and conditions';
      isValid = false;
    }

    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSignup = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      try {
        await axios.post('https://ituzebe.onrender.com/api/auth/signup', formData);
        alert('Signup successful!');
        navigate('/signin');
      } catch (error) {
        console.error('Signup failed', error);
        alert('Signup failed');
      }
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-cover bg-center relative" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="absolute inset-0 bg-black opacity-50 z-1"></div>
      <Link to="/signin" className="absolute top-6 left-6 text-white text-2xl z-2">
        <FaArrowLeft />
      </Link>

      <div className="relative z-2 flex justify-between items-center w-full max-w-4xl p-8 rounded shadow-lg bg-white bg-opacity-80 flex-wrap">
        {/* Left side with logo and welcome message */}
        <div className="flex flex-col items-center justify-center w-full lg:w-1/2 pr-8 mb-8 lg:mb-0">
          <img src={logo} alt="Logo" className="mb-4" style={{ width: '150px', height: 'auto' }} />
          <h1 className="text-2xl font-bold text-gray-800 text-center">Welcome to Ituze Garden Palace</h1>
          <p className="mt-2 text-lg text-gray-600 text-center max-w-md">
            Join us today and experience the best of hospitality! Become our partner and discover exceptional services you’ll love.
          </p>
        </div>

        {/* Right side with form */}
        <div className="w-full lg:w-1/2">
          <h2 className="text-3xl font-semibold mb-6 text-center text-gray-800">Create an Account</h2>
          <form onSubmit={handleSignup} className="text-center space-y-4">
            <div className="grid grid-cols-1 gap-6">
              <div>
                <input
                  type="text"
                  id="firstname"
                  name="firstname"
                  placeholder="Firstname"
                  onChange={handleInputChange}
                  className={`input-field font-bold bg-transparent border-b-2 border-gray-500 text-gray-800 w-full p-2 ${errors.firstname ? 'border-red-500' : ''}`}
                  required
                />
                {errors.firstname && <p className="text-red-500 text-sm">{errors.firstname}</p>}
              </div>

              <div>
                <input
                  type="text"
                  id="lastname"
                  name="lastname"
                  placeholder="Lastname"
                  onChange={handleInputChange}
                  className={`input-field font-bold bg-transparent border-b-2 border-gray-500 text-gray-800 w-full p-2 ${errors.lastname ? 'border-red-500' : ''}`}
                  required
                />
                {errors.lastname && <p className="text-red-500 text-sm">{errors.lastname}</p>}
              </div>

              <div>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  onChange={handleInputChange}
                  className={`input-field font-bold bg-transparent border-b-2 border-gray-500 text-gray-800 w-full p-2 ${errors.email ? 'border-red-500' : ''}`}
                  required
                />
                {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
              </div>

              <div>
                <input
                  type="text"
                  id="username"
                  name="username"
                  placeholder="Username"
                  onChange={handleInputChange}
                  className={`input-field font-bold bg-transparent border-b-2 border-gray-500 text-gray-800 w-full p-2 ${errors.username ? 'border-red-500' : ''}`}
                  required
                />
                {errors.username && <p className="text-red-500 text-sm">{errors.username}</p>}
              </div>

              <div>
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                  onChange={handleInputChange}
                  className={`input-field font-bold bg-transparent border-b-2 border-gray-500 text-gray-800 w-full p-2 ${errors.password ? 'border-red-500' : ''}`}
                  required
                />
                {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
              </div>

              <div>
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  onChange={handleInputChange}
                  className={`input-field font-bold bg-transparent border-b-2 border-gray-500 text-gray-800 w-full p-2 ${errors.confirmPassword ? 'border-red-500' : ''}`}
                  required
                />
                {errors.confirmPassword && <p className="text-red-500 text-sm">{errors.confirmPassword}</p>}
              </div>

              <div className="flex items-center justify-center space-x-2">
                <input
                  type="checkbox"
                  id="agree"
                  name="agree"
                  onChange={handleInputChange}
                  checked={formData.agree}
                  className="h-5 w-5 text-orange-500"
                  required
                />
                <label htmlFor="agree" className="text-gray-800">
                  I agree to the terms and conditions
                </label>
              </div>
              {errors.agree && <p className="text-red-500 text-sm">{errors.agree}</p>}

              <button type="submit" className="bg-orange-600 hover:bg-orange-500 text-white py-3 w-full rounded-lg font-semibold transition duration-300">
                Sign Up
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
