import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { FaBars, FaUser } from 'react-icons/fa';
import { FaTimes } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../Assets/logo.jpg';
import Footer from './Footer';

const ReservationCrud = () => {
  const [reservations, setReservations] = useState([]);

  const [isOpen, setIsOpen] = useState(false); // State to manage the navbar for mobile view
const [isOpenUserMenu, setIsOpenUserMenu] = useState(false); // State for user menu
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

 

const fetchReservations = useCallback(async () => {
  try {
    const res = await axios.get('https://ituzebe.onrender.com/api/reservations', {
      headers: { Authorization: `Bearer ${token}` },
    });
    setReservations(res.data);
  } catch (err) {
    console.error(err);
  }
}, [token]);

useEffect(() => {
  fetchReservations();
}, [fetchReservations]);

  const handleLogout = () => {
    localStorage.removeItem('Token');
    navigate('/signin');
  };


  const handleNavbarToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-yellow-100  text-white flex flex-col" style={{ fontFamily: '"Nunito", sans-serif' }}>
       {/* Header section */}
      <header className="sticky top-0 z-20 shadow-lg flex justify-between items-center p-4 bg-gradient-to-r from-orange-900 to-green-900 mx-5 my-2 rounded-full backdrop-blur-lg bg-opacity-80 transition-all duration-300">
        <div className="flex items-center space-x-4 mx-5">
          <img src={logo} alt="Ituze Garden Palace Logo" className="h-12 w-12 rounded-full object-cover border-2 border-white shadow-md hover:scale-105 transition-transform duration-300" />
        </div>
        <div className="flex items-center space-x-6">
          <button onClick={handleNavbarToggle} className="text-2xl md:hidden text-white hover:text-red-500 transition-colors duration-300">
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
          <nav className={`flex-col md:flex-row ${isOpen ? 'flex' : 'hidden'} md:flex`}>
            <ul className="block md:flex md:space-x-8 md:items-center text-white font-semibold md:ml-auto">
              <li className="hover:underline hover:text-red-500 transition duration-300"><Link to="/">Home</Link></li>
              <li className="hover:underline hover:text-red-500 transition duration-300"><Link to="/gallery">Gallery</Link></li>
              <li className="hover:underline hover:text-red-500 transition duration-300"><Link to="/reservation">Reservation</Link></li>
              <li className="hover:underline hover:text-red-500 transition duration-300"><Link to="/menu">Menu</Link></li>
              <li className="hover:underline hover:text-red-500 transition duration-300"><Link to="/contact">Contacts</Link></li>
            </ul>
          </nav>
          <div className="relative">
            <FaUser className="text-2xl text-white cursor-pointer hover:text-red-500 transition duration-300" onClick={() => setIsOpenUserMenu(!isOpenUserMenu)} />
            {isOpenUserMenu && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 text-black z-30">
                <Link to="/profile" className="block px-4 py-2 hover:bg-gray-100 transition duration-200">Profile</Link>
                <button onClick={handleLogout} className="block w-full text-left px-4 py-2 hover:bg-gray-100 transition duration-200">Log Out</button>
              </div>
            )}
          </div>
        </div>
      </header>

      {/* Main Content */}
      <section className="py-16 bg-orange-100 text-center">
       <h1 className="text-2xl font-bold mb-5 text-gray-600">Available Reservations</h1>
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4">
          {reservations.map((reservation) => (
            <div key={reservation._id} className="bg-gray-800 rounded-lg shadow-lg p-6 text-left">
              <h3 className="text-xl font-bold mb-2 text-orange-400">{reservation.name}</h3>
              <p className="mb-2 text-gray-300">{reservation.description}</p>
              <p className="text-lg font-semibold text-white">RF {reservation.price}</p>
              <button
               
                className="mt-4 bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded transition"
              >
                Confirm with Deposit
              </button>
            </div>
          ))}
        </div>
      </section>

     <Footer/>
    </div>
  );
};

export default ReservationCrud;
