import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';


const Reservation = () => {
  const [reservations, setReservations] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    seats: '',
    description: '',
    price: '',
  });
  const [updatingId, setUpdatingId] = useState(null);
  const token = localStorage.getItem('token');

  

const fetchReservations = useCallback(async () => {
  try {
    const res = await axios.get('https://ituzebe.onrender.com/api/reservations', {
      headers: { Authorization: `Bearer ${token}` },
    });
    setReservations(res.data);
  } catch (err) {
    console.error(err);
  }
}, [token]);

useEffect(() => {
  fetchReservations();
}, [fetchReservations]);

  const handleCreate = async (event) => {
    event.preventDefault();
    try {
      await axios.post('https://ituzebe.onrender.com/api/reservations', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setFormData({
        name: '',
        seats: '',
        description: '',
        price: '',
      });
      fetchReservations();
    } catch (err) {
      console.error(err);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://ituzebe.onrender.com/api/reservations/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchReservations();
    } catch (err) {
      console.error(err);
    }
  };

  const handleUpdate = async () => {
    try {
      await axios.put(`https://ituzebe.onrender.com/api/reservations/${updatingId}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setFormData({
        name: '',
        seats: '',
        description: '',
        price: '',
      });
      setUpdatingId(null);
      fetchReservations();
    } catch (err) {
      console.error(err);
    }
  };

  const handleEdit = (reservation) => {
    setFormData({
      name: reservation.name,
      seats: reservation.seats,
      description: reservation.description,
      price: reservation.price,
    });
    setUpdatingId(reservation._id);
  };

  return (
    <div className="min-h-screen bg-stone-900 text-white flex flex-col font-Arial">
      

      {/* Main Content */}
      <main className="container mx-auto px-4 py-8 flex-grow bg-white text-black">
        <h1 className="text-4xl font-bold mb-8">Reservation CRUD</h1>
        <div className="mb-8">
          <h2 className="text-3xl font-semibold mb-4">Add New Reservation</h2>
          <form onSubmit={handleCreate} className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring focus:border-blue-500"
            />
            <input
              type="number"
              name="seats"
              placeholder="Seats"
              value={formData.seats}
              onChange={(e) => setFormData({ ...formData, seats: e.target.value })}
              className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring focus:border-blue-500"
            />
            <textarea
              name="description"
              placeholder="Description"
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring focus:border-blue-500"
              rows="3"
            />
            <input
              type="number"
              name="price"
              placeholder="Price"
              value={formData.price}
              onChange={(e) => setFormData({ ...formData, price: e.target.value })}
              className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring focus:border-blue-500"
            />
            {updatingId ? (
              <button
                type="button"
                onClick={handleUpdate}
                className="col-span-2 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200"
              >
                Update Reservation
              </button>
            ) : (
              <button
                type="submit"
                className="col-span-2 bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-200"
              >
                Add Reservation
              </button>
            )}
          </form>
        </div>

        <h2 className="text-3xl font-semibold mb-4">Reservations</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {reservations.map((reservation) => (
            <div key={reservation._id} className="bg-white shadow-lg rounded-lg overflow-hidden">
              <div className="px-6 py-4">
                <h3 className="text-lg font-semibold mb-2">{reservation.name}</h3>
                <p className="text-gray-600 mb-2">Seats: {reservation.seats}</p>
                <p className="text-gray-600 mb-2">Description: {reservation.description}</p>
                <p className="text-gray-600 mb-2">Price: ${reservation.price}</p>
                <div className="flex justify-end mt-4 space-x-2">
                  <button
                    onClick={() => handleEdit(reservation)}
                    className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200"
                  >
                <FaEdit />  
                  </button>
                  <button
                    onClick={() => handleDelete(reservation._id)}
                    className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition duration-200"
                  >
                     <FaTrashAlt />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </main>

      
    </div>
  );
};

export default Reservation;
