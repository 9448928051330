import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaTrashAlt, FaPen } from 'react-icons/fa';
import Filter from './Filter';

const Gallery = () => {
  const [photos, setPhotos] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [error, setError] = useState(null);
  const token = localStorage.getItem('token');

  // Fetch photos and categories
  useEffect(() => {
    axios.get('https://ituzebe.onrender.com/api/photos/photos', {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(res => {
        setPhotos(res.data);
        const uniqueCategories = [...new Set(res.data.map(photo => photo.category))];
        setCategories(uniqueCategories);
      })
      .catch(err => {
        console.error(err);
        setError('Failed to load photos');
      });
  }, [token]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const handleCreate = async (newPhotoData) => {
    try {
      const formData = new FormData();
      formData.append('title', newPhotoData.title);
      formData.append('category', newPhotoData.category);
      formData.append('image', newPhotoData.image); // Image file
      formData.append('description', newPhotoData.description);

      const res = await axios.post('https://ituzebe.onrender.com/api/photos/photos', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setPhotos([...photos, res.data]);
    } catch (err) {
      console.error(err);
      setError('Failed to add photo');
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://ituzebe.onrender.com/api/photos/photos/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPhotos(photos.filter((photo) => photo._id !== id));
    } catch (err) {
      console.error(err);
      setError('Failed to delete photo');
    }
  };

  const handleUpdate = async (id, updatedData) => {
    try {
      const formData = new FormData();
      formData.append('title', updatedData.title);
      formData.append('category', updatedData.category);
      if (updatedData.image) formData.append('image', updatedData.image);
      formData.append('description', updatedData.description);

      const res = await axios.put(`https://ituzebe.onrender.com/api/photos/photos/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setPhotos(photos.map((photo) => (photo._id === id ? res.data : photo)));
    } catch (err) {
      console.error(err);
      setError('Failed to update photo');
    }
  };

  const filteredPhotos = selectedCategory
    ? photos.filter((photo) => photo.category === selectedCategory)
    : photos;

  return (
    <div className="min-h-screen flex flex-col font-sans bg-gray-50">
      <div className="container mx-auto px-6 py-10 bg-white shadow-lg rounded-lg">
        <div className="mb-10 text-center">
          <h1 className="text-5xl font-bold text-gray-800 mb-6">Our Beautiful Gallery</h1>
          {error && <p className="text-red-600">{error}</p>}
          <Filter
            categories={categories}
            selectedCategory={selectedCategory}
            handleCategoryChange={handleCategoryChange}
          />
        </div>

        {/* Form for adding photos */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const formData = new FormData(e.target);
            const newPhotoData = {
              title: formData.get('title'),
              category: formData.get('category'),
              image: formData.get('image'),
              description: formData.get('description'),
            };
            handleCreate(newPhotoData);
            e.target.reset();
          }}
          className="mb-12 bg-gray-100 p-6 rounded-lg shadow-md"
        >
          <h2 className="text-2xl font-semibold mb-4 text-gray-700">Add a New Photo</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <input
              type="text"
              name="title"
              placeholder="Photo Title"
              required
              className="input-field"
            />
            <input
              type="text"
              name="category"
              placeholder="Category"
              required
              className="input-field"
            />
            <input
              type="file"
              name="image"
              required
              className="input-field"
            />
            <textarea
              name="description"
              placeholder="Description"
              className="input-field"
              rows="3"
            ></textarea>
          </div>
          <button type="submit" className="btn-submit mt-6">Upload Photo</button>
        </form>

        {/* Photo Gallery */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {filteredPhotos.map((photo) => (
            <div key={photo._id} className="relative group">
              <img
                src={`https://ituzebe.onrender.com/api/photos/photos/image/${photo.imagePath.split('/').pop()}`}
                alt={photo.title}
                className="w-full h-72 object-cover rounded-lg shadow-md transition duration-300 transform group-hover:scale-105"
              />
              <div className="absolute inset-0 bg-black bg-opacity-40 opacity-0 group-hover:opacity-100 transition duration-300 flex items-center justify-center">
                <div className="text-white text-center">
                  <h2 className="text-2xl font-bold mb-2">{photo.title}</h2>
                  <p className="mb-4">{photo.description}</p>
                  <div className="flex justify-center space-x-4">
                    <button onClick={() => handleDelete(photo._id)} className="text-red-500 hover:text-red-700">
                      <FaTrashAlt size={24} />
                    </button>
                    <button onClick={() => handleUpdate(photo._id, photo)} className="text-blue-500 hover:text-blue-700">
                      <FaPen size={24} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
