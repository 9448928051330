import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { FaBars, FaUser } from 'react-icons/fa';
import { FaTimes } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../Assets/logo.jpg';
import Footer from './Footer';

const Menu = () => {
  const [menus, setMenus] = useState([]);
  const [isOpen, setIsOpen] = useState(false); // For mobile menu toggle
  const [isOpenUserMenu, setIsOpenUserMenu] = useState(false); // For user menu toggle
  const [activeTab, setActiveTab] = useState('Foods'); // Track the active tab
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  const fetchMenus = useCallback(async () => {
    try {
      const res = await axios.get('https://ituzebe.onrender.com/api/menus', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMenus(res.data);
    } catch (err) {
      console.error(err);
    }
  }, [token]);

  useEffect(() => {
    fetchMenus();
  }, [fetchMenus]);

  const handleNavbarToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('Token');
    navigate('/signin');
  };

const renderMenuItems = (type) => {
  return menus
    .filter(menu => menu.type === type)
    .reduce((categories, menu) => {
      if (!categories[menu.category]) {
        categories[menu.category] = [];
      }
      categories[menu.category].push(menu);
      return categories;
    }, {})
};

  return (
    <div className="min-h-screen bg-gradient-to-r from-yellow-100  text-white" style={{ fontFamily: '"Nunito", sans-serif' }}>
      <header className="sticky top-0 z-20 shadow-lg flex justify-between items-center p-4 bg-gradient-to-r from-orange-900 to-green-900 mx-5 my-2  mx-5 rounded-full backdrop-blur-lg bg-opacity-80 transition-all duration-300">
        <div className="flex items-center space-x-4 mx-5">
          <img src={logo} alt="Ituze Garden Palace Logo" className="h-12 w-12 rounded-full object-cover border-2 border-white shadow-md hover:scale-105 transition-transform duration-300" />
        </div>
        <div className="flex items-center space-x-6">
          <button onClick={handleNavbarToggle} className="text-2xl md:hidden text-white hover:text-red-500 transition-colors duration-300">
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
          <nav className={`flex-col md:flex-row ${isOpen ? 'flex' : 'hidden'} md:flex`}>
            <ul className="block md:flex md:space-x-8 md:items-center text-white font-semibold md:ml-auto">
              <li className="hover:underline hover:text-red-500 transition duration-300"><Link to="/">Home</Link></li>
              <li className="hover:underline hover:text-red-500 transition duration-300"><Link to="/gallery">Gallery</Link></li>
              <li className="hover:underline hover:text-red-500 transition duration-300"><Link to="/reservation">Reservation</Link></li>
              <li className="hover:underline hover:text-red-500 transition duration-300"><Link to="/menu">Menu</Link></li>
              <li className="hover:underline hover:text-red-500 transition duration-300"><Link to="/contact">Contacts</Link></li>
            </ul>
          </nav>
          <div className="relative">
            <FaUser className="text-2xl text-white cursor-pointer hover:text-red-500 transition duration-300" onClick={() => setIsOpenUserMenu(!isOpenUserMenu)} />
            {isOpenUserMenu && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 text-black z-30">
                <Link to="/profile" className="block px-4 py-2 hover:bg-gray-100 transition duration-200">Profile</Link>
                <button onClick={handleLogout} className="block w-full text-left px-4 py-2 hover:bg-gray-100 transition duration-200">Log Out</button>
              </div>
            )}
          </div>
        </div>
      </header>


      {/* Main Content */}
    <div className="min-h-screen mx-auto px-4 py-8 bg-gradient-to-br from-yellow-100 via-gray-100  text-black">
      <div className="mb-10">
        <div className="flex justify-center space-x-6 mb-6">
          <button
            className={`py-2 px-4 text-sm font-medium ${activeTab === 'Foods' ? 'border-b-2 border-orange-600 text-orange-600' : 'text-gray-600 hover:text-orange-600'}`}
            onClick={() => setActiveTab('Foods')}
          >
            Food Menu
          </button>
          <button
            className={`py-2 px-4 text-sm font-medium ${activeTab === 'Drinks' ? 'border-b-2 border-blue-600 text-blue-600' : 'text-gray-600 hover:text-blue-600'}`}
            onClick={() => setActiveTab('Drinks')}
          >
            Drinks Menu
          </button>
        </div>

        <h2 className="text-2xl font-semibold mb-6 text-center text-orange-700">{activeTab} Menu</h2>
        {Object.keys(renderMenuItems(activeTab)).map((category) => (
          <div key={category} className="mb-10">
            <h3 className="text-xl font-semibold mb-4 text-orange-800">{category}</h3>
            {renderMenuItems(activeTab)[category].map(menu => (
              <div
                key={menu._id}
                className="flex justify-between items-start mb-6 p-4 bg-gradient-to-br from-green-600 via-green-900 to-green-800 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105"
              >
                <div>
                  <h4 className="text-lg font-medium text-yellow-200">{menu.name}</h4>
                  <p className="text-sm text-yellow-50 mt-1">{menu.description}</p>
                </div>
                <span className="text-base font-semibold text-yellow-300">{menu.price}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>

     <Footer/>
    </div>
  );
};

export default Menu;
