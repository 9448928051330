import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaTimes, FaExpandAlt, FaUser, FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import logo from '../Assets/logo.jpg';
import Filter from './Filter';
import Footer from './Footer';

const GalleryCrud = () => {
  const [photos, setPhotos] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null); // For full-size image
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenUserMenu, setIsOpenUserMenu] = useState(false);
  const token = localStorage.getItem('token');

  useEffect(() => {
    axios
      .get('https://ituzebe.onrender.com/api/photos/photos', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setPhotos(res.data);
        const uniqueCategories = [
          ...new Set(res.data.map((photo) => photo.category)),
        ];
        setCategories(uniqueCategories);
      })
      .catch((err) => console.error(err));
  }, [token]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const openModal = (image) => {
    setModalImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage(null);
  };

  const filteredPhotos = selectedCategory
    ? photos.filter((photo) => photo.category === selectedCategory)
    : photos;

  return (
    <div
      className="min-h-screen bg-gradient-to-r from-yellow-100 text-white flex flex-col"
      style={{ fontFamily: '"Nunito", sans-serif' }}
    >
      <header className="sticky top-0 z-20 shadow-lg flex justify-between items-center p-4 bg-gradient-to-r from-orange-900 to-green-900 mx-5 my-2 mx-5 rounded-full backdrop-blur-lg bg-opacity-80 transition-all duration-300">
        <div className="flex items-center space-x-4 mx-5">
          <img
            src={logo}
            alt="Ituze Garden Palace Logo"
            className="h-12 w-12 rounded-full object-cover border-2 border-white shadow-md hover:scale-105 transition-transform duration-300"
          />
        </div>
        <div className="flex items-center space-x-6">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-2xl md:hidden text-white hover:text-red-500 transition-colors duration-300"
          >
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
          <nav className={`flex-col md:flex-row ${isOpen ? 'flex' : 'hidden'} md:flex`}>
            <ul className="block md:flex md:space-x-8 md:items-center text-white font-semibold md:ml-auto">
              <li className="hover:underline hover:text-red-500 transition duration-300">
                <Link to="/">Home</Link>
              </li>
              <li className="hover:underline hover:text-red-500 transition duration-300">
                <Link to="/gallery">Gallery</Link>
              </li>
              <li className="hover:underline hover:text-red-500 transition duration-300">
                <Link to="/reservation">Reservation</Link>
              </li>
              <li className="hover:underline hover:text-red-500 transition duration-300">
                <Link to="/menu">Menu</Link>
              </li>
              <li className="hover:underline hover:text-red-500 transition duration-300">
                <Link to="/contact">Contacts</Link>
              </li>
            </ul>
          </nav>
          <div className="relative">
            <FaUser
              className="text-2xl text-white cursor-pointer hover:text-red-500 transition duration-300"
              onClick={() => setIsOpenUserMenu(!isOpenUserMenu)}
            />
            {isOpenUserMenu && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 text-black z-30">
                <Link
                  to="/profile"
                  className="block px-4 py-2 hover:bg-gray-100 transition duration-200"
                >
                  Profile
                </Link>
                <button
                  onClick={() => localStorage.removeItem('token')}
                  className="block w-full text-left px-4 py-2 hover:bg-gray-100 transition duration-200"
                >
                  Log Out
                </button>
              </div>
            )}
          </div>
        </div>
      </header>

      {/* Main content */}
      <div className="container mx-auto px-4 py-8 bg-gradient-to-r from-yellow-100 ">
        <div className="mb-8 text-black">
          <h1 className="text-4xl font-semibold mb-4 text-center">Explore Our Gallery</h1>
          <Filter
            categories={categories}
            selectedCategory={selectedCategory}
            handleCategoryChange={handleCategoryChange}
          />
        </div>

        {/* Display Photos */}
        {filteredPhotos.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
            {filteredPhotos.map((photo) => (
              <div
                key={photo._id}
                className="relative group bg-white rounded-lg overflow-hidden shadow-lg"
              >
                <img
                  src={`https://ituzebe.onrender.com/api/photos/photos/image/${photo.imagePath.split('/').pop()}`}
                  alt={photo.title}
                  className="w-full h-64 object-cover transform transition-transform duration-300 group-hover:scale-110"
                />

                {/* Hover effect with View Icon */}
                <div
                  onClick={() => openModal(photo.imagePath)}
                  className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 cursor-pointer"
                >
                  <FaExpandAlt className="text-white text-4xl" />
                </div>

                <div className="p-4">
                  <h3 className="text-lg font-semibold">{photo.title}</h3>
                  <p className="text-gray-600">
                    {photo.description.length > 100
                      ? `${photo.description.slice(0, 100)}...`
                      : photo.description}
                  </p>
                  <p className="text-sm text-gray-500 mt-2">{photo.category}</p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center">
            <p className="text-xl">No photos found.</p>
          </div>
        )}
      </div>

      {/* Modal for full-size image */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
          <div className="relative">
            <FaTimes
              className="text-white text-3xl absolute top-4 right-4 cursor-pointer hover:text-red-500 transition duration-300"
              onClick={closeModal}
            />
            <img
              src={`https://ituzebe.onrender.com/api/photos/photos/image/${modalImage.split('/').pop()}`}
              alt="Full"
              className="max-w-full max-h-screen"
            />
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default GalleryCrud;
