import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaUser, FaBars } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import backgroundImage from '../Assets/restaurant.jpg';
import chickenImage from '../Assets/chicken.jpg';
import logo from '../Assets/logo.jpg';
import { FaTimes } from 'react-icons/fa'; 
import loadingGif from '../Assets/loading.gif';
import Footer from './Footer';

const LandingPage = () => {
  const [reservations, setReservations] = useState([]);
  const [isOpenUserMenu, setIsOpenUserMenu] = useState(false); 
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Simulating loading for 8 seconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    // Fetch reservations after the loading completes
    fetchReservations();

    // Clear the timer when component unmounts
    return () => clearTimeout(timer);
  }, []);

  const fetchReservations = async () => {
    try {
      const response = await axios.get('https://ituzebe.onrender.com/api/reservations');
      setReservations(response.data);
    } catch (error) {
      console.error('Error fetching reservations:', error);
    }
  };


  const handleNavbarToggle = () => {
    setIsOpen(!isOpen);
  };

  
  const handleLogout = () => {
    localStorage.removeItem('Token');
    navigate('/signin');
  };

  if (loading) {
    // Return a full-page loading spinner during the loading state
    return (
      <div className="bg-white flex items-center justify-center " >
        .    <img
      src={loadingGif}
      alt="loading....."
      
    />
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-r from-yellow-100  to-yellow-200 min-h-screen text-white flex flex-col " style={{ fontFamily: '"Nunito", sans-serif' }}>
       {/* Header section */}
      <header className="sticky top-0 z-20 shadow-lg flex justify-between items-center p-4 bg-gradient-to-r from-orange-900 to-green-900 mx-5 my-2 rounded-full backdrop-blur-lg bg-opacity-80 transition-all duration-300">
  {/* Logo Section */}
  <div className="flex items-center space-x-4 mx-5">
    <img
      src={logo}
      alt="Ituze Garden Palace Logo"
      className="h-12 w-12 rounded-full object-cover border-2 border-white shadow-md hover:scale-105 transition-transform duration-300"
    />
  </div>

  {/* Navigation and Hamburger Menu */}
  <div className="flex items-center space-x-6">
    {/* Hamburger Menu for Mobile */}
    <button
      onClick={handleNavbarToggle}
      className="text-2xl md:hidden text-white hover:text-red-500 transition-colors duration-300"
    >
      {isOpen ? <FaTimes /> : <FaBars />}
    </button>

    {/* Navigation Links */}
    <nav className={`flex-col md:flex-row ${isOpen ? 'flex' : 'hidden'} md:flex`}>
      <ul className="block md:flex md:space-x-8 md:items-center text-white font-semibold md:ml-auto">
        <li className="hover:underline hover:text-red-500 transition duration-300">
          <Link to="/">Home</Link>
        </li>
        <li className="hover:underline hover:text-red-500 transition duration-300">
          <Link to="/gallery">Gallery</Link>
        </li>
        <li className="hover:underline hover:text-red-500 transition duration-300">
          <Link to="/reservation">Reservation</Link>
        </li>
        <li className="hover:underline hover:text-red-500 transition duration-300">
          <Link to="/menu">Menu</Link>
        </li>
        <li className="hover:underline hover:text-red-500 transition duration-300">
          <Link to="/contact">Contacts</Link>
        </li>
      </ul>
    </nav>

    {/* User Menu */}
    <div className="relative">
      <FaUser
        className="text-2xl text-white cursor-pointer hover:text-red-500 transition duration-300"
        onClick={() => setIsOpenUserMenu(!isOpenUserMenu)}
      />
      {isOpenUserMenu && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 text-black z-30">
          <Link
            to="/profile"
            className="block px-4 py-2 hover:bg-gray-100 transition duration-200"
          >
            Profile
          </Link>
          <button
            onClick={handleLogout}
            className="block w-full text-left px-4 py-2 hover:bg-gray-100 transition duration-200"
          >
            Signin
          </button>
        </div>
      )}
    </div>
  </div>
</header>

      <main className="flex-1 flex items-center justify-center text-center py-20 relative m-5 rounded"
        style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <div className="p-8 bg-black bg-opacity-60 rounded-lg space-y-6 max-w-lg mx-auto">
          <h2 className="text-2xl md:text-4xl font-bold text-orange-400">Restaurant Experience</h2>
          <p className="text-lg md:text-2xl">
            Experience Ituze Garden Palace: Fine dining, garden views, and the best craft cocktails in Kigali.
          </p>
          <button className="mt-8 mb-32">
            <Link to="/menu" className="bg-transparent border border-white px-6 py-3 rounded-lg hover:bg-white hover:text-black transition">
              View Menu
            </Link>
          </button>
        </div>
      </main>

      <section className="py-16 bg-gradient-to-r from-yellow-100  to-yellow-200 text-center">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4">
          {reservations.map((reservation) => (
            <div key={reservation._id} className="bg-gray-800 rounded-lg shadow-lg p-6 text-left">
              <h3 className="text-xl font-bold mb-2 text-orange-400">{reservation.name}</h3>
              <p className="mb-2 text-gray-300">{reservation.description}</p>
              <p className="text-lg font-semibold text-white">RF {reservation.price}</p>
              <button
                className="mt-4 bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded transition"
              >
                Confirm with Deposit
              </button>
            </div>
          ))}
        </div>
      </section>

      <section className="relative py-16 text-center bg-fixed bg-cover bg-center text-white mx-5 my-2 rounded"
        style={{ backgroundImage: `url(${chickenImage})` }}>
        <div className="bg-black bg-opacity-70 py-16 px-8 rounded-lg max-w-lg mx-auto">
          <h2 className="text-4xl md:text-5xl font-bold text-orange-400">Satisfy Your Craving</h2>
          <p className="text-2xl md:text-3xl font-semibold mt-4">Order Online</p>
          <Link to="/order" className="mt-6 inline-block bg-transparent border border-white px-8 py-3 rounded-lg hover:bg-white hover:text-black transition">
            Order Now
          </Link>
        </div>
      </section>

<section className="bg-gradient-to-r from-yellow-100  to-yellow-200 py-16 text-center">
  <Carousel
    showThumbs={false}
    showStatus={false}
    infiniteLoop
    autoPlay
    interval={5000}
    transitionTime={500}
    className="w-full max-w-4xl mx-auto"
  >
    {/* Testimonial 1 */}
    <div className="bg-white p-8 rounded-lg shadow-lg">
      <h3 className="text-2xl font-semibold mb-5 text-gray-800">Jackson Duvert</h3>
      <p className="text-gray-700">This place is very nice and has great food. The view is amazing at night!! A must-go.</p>
    </div>

    {/* Testimonial 2 */}
    <div className="bg-white p-8 rounded-lg shadow-lg">
      <h3 className="text-2xl font-semibold mb-5 text-gray-800">Emma Johnson</h3>
      <p className="text-gray-700">I had a wonderful experience staying here! The staff was friendly, the rooms were clean, and the ambiance was peaceful. Definitely recommend it for a getaway.</p>
    </div>

    {/* Testimonial 3 */}
    <div className="bg-white p-8 rounded-lg shadow-lg">
      <h3 className="text-2xl font-semibold mb-5 text-gray-800">Liam Williams</h3>
      <p className="text-gray-700">One of the best places to visit in Kigali. The service was top-notch, and the garden views are to die for. I'll be coming back soon.</p>
    </div>

    {/* Testimonial 4 */}
    <div className="bg-white p-8 rounded-lg shadow-lg">
      <h3 className="text-2xl font-semibold mb-5 text-gray-800">Sophia Martinez</h3>
      <p className="text-gray-700">The food, atmosphere, and staff were all amazing. This place exceeded my expectations in every way. A perfect spot for a relaxing weekend.</p>
    </div>

    {/* Testimonial 5 */}
    <div className="bg-white p-8 rounded-lg shadow-lg">
      <h3 className="text-2xl font-semibold mb-5 text-gray-800">Michael Brown</h3>
      <p className="text-gray-700">Incredible experience from start to finish. The peaceful garden setting combined with the high-quality service, made this one of my favorite places in Rwanda.</p>
    </div>
  </Carousel>
</section>


      <section className="bg-gradient-to-r from-teal-900  to-gray-950  text-white py-12 text-center m-8 rounded">
        <div className="container mx-auto flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4 px-4">
          <input
            type="email"
            placeholder="Enter your email here*"
            className="bg-gray-200 border border-gray-600 px-4 py-2 rounded focus:outline-none text-black"
          />
          <button className="bg-orange-500 px-6 py-3 rounded text-lg font-semibold hover:bg-orange-600 transition">
            Subscribe Now
          </button>
        </div>
      </section>
     <Footer/>
    </div>
  );
};

export default LandingPage;
