import React, { useState } from 'react';
import { FaArrowLeft} from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import backgroundImage from '../Assets/vector.jpg';
import logo from '../Assets/logo.jpg'; // Import the logo

const SignIn = () => {
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCredentials((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
     const response = await axios.post('https://ituzebe.onrender.com/api/auth/signin', credentials);
       const { token, loggedInUser } = response.data;
       localStorage.setItem('token', token);
       localStorage.setItem('loggedInUser', JSON.stringify(loggedInUser));
       alert('Signup successful!');
        if (loggedInUser.role === 'user') {
          navigate('/landingpage');
        } else if (loggedInUser.role === 'administrator') {
          navigate('/dashboard/reserve');
        } else {
          navigate('/dashboard/welcome'); 
        }
      }  catch (err) {
      setError('Error signing up');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-cover bg-center relative" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="absolute inset-0 bg-black opacity-50 z-1"></div>
      <Link to="/" className="absolute top-6 left-6 text-white text-2xl z-2">
        <FaArrowLeft />
      </Link>

      <div className="relative z-2 flex flex-col items-center justify-center w-full max-w-md p-8 rounded-lg shadow-lg bg-white bg-opacity-80">
        <img src={logo} alt="Logo" className="mb-6" style={{ width: '150px', height: 'auto' }} />
        <h2 className="text-3xl font-semibold text-gray-800 mb-6">Sign In</h2>
        <form onSubmit={handleLogin} className="w-full space-y-4">
          <div>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              onChange={handleInputChange}
              required
              className="input-field font-bold bg-transparent border-b-2 border-gray-500 text-gray-800 w-full p-2"
            />
          </div>

          <div>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              onChange={handleInputChange}
              required
              className="input-field font-bold bg-transparent border-b-2 border-gray-500 text-gray-800 w-full p-2"
            />
          </div>

          {error && <p className="text-red-500 text-sm">{error}</p>}

          <button type="submit" className="bg-orange-600 hover:bg-orange-500 text-white py-3 w-full rounded-lg font-semibold transition duration-300">
            Sign In
          </button>
        </form>

        <p className="mt-4 text-gray-600">
          Don't have an account?{' '}
          <Link to="/signup" className="text-orange-500 hover:text-orange-600">
            Sign Up
          </Link>
        </p>
      </div>
    </div>
  );
};

export default SignIn;
