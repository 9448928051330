import React from 'react';
import { FaWhatsapp, FaEnvelope, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-gradient-to-r from-pink-900 via-orange-600 to-green-900 text-light py-8 font-bold" style={{ fontFamily: '"Nunito", sans-serif' }}>
      <div className="container mx-auto px-4 md:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-8 md:space-y-0">
          {/* Location Info */}
          <div className="text-center md:text-left text-sm space-y-2">
            <p className="text-gray-400">Rwanda</p>
            <p className="text-gray-400">Kigali - Masaka</p>
          </div>

          {/* Brand and Social Links */}
          <div className="text-center">
            <h2 className="text-2xl font-semibold text-white mb-4">Ituze Garden Palace</h2>
            <ul className="flex justify-center space-x-6">
              <li>
                <a href="https://wa.me/250784503706" aria-label="WhatsApp" className="hover:text-orange-400 transition-colors duration-300">
                  <FaWhatsapp className="text-xl" />
                </a>
              </li>
              <li>
                <a href="mailto:ituzegardenpalace@gmail.com" aria-label="Email" className="hover:text-orange-400 transition-colors duration-300">
                  <FaEnvelope className="text-xl" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/ituzegardenpalace" aria-label="Instagram" className="hover:text-orange-400 transition-colors duration-300">
                  <FaInstagram className="text-xl" />
                </a>
              </li>
            </ul>
          </div>

          {/* Google Maps Embed */}
          <div className="text-center md:text-right">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3987.3700937917183!2d30.189746909868628!3d-2.007285197966163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19db5754ee2e87f5%3A0x8d6336f2eaad9694!2sItuze%20garden%20palace!5e0!3m2!1sen!2srw!4v1719416061512!5m2!1sen!2srw"
              width="300"
              height="200"
              style={{ border: '0' }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="rounded-lg shadow-lg"
              title="Ituze Garden Palace Location"
            ></iframe>
          </div>
        </div>

        {/* Copyright Section */}
        <div className="text-center text-xs text-gray-400 mt-4">
          <p>&copy; 2024 Ituze Garden Palace. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
